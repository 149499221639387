<template>
  <article class="terms-of-service-wrapper">
    <h3 class="h3 terms-of-service-content-center">
      Terms and Conditions of Use
    </h3>

    <ol
      class="
        terms-of-service-content-wrapper
        terms-of-service-content-list-numbered-decimal
      "
    >
      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">About Talkie App</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                The Talkie App and Website have been produced by Talkie Limited,
                Phoenix Square, 4 Midland Street, Leicester, LE1 1TG, United
                Kingdom (“We” and “Us”). Talkie App is a language learning web
                application specialising in developing the speaking and
                listening skills of users (“You” and “Your”).
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Talkie App User Licence Terms</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                You must read these Terms and Conditions of Use (“Terms”)
                carefully before using the App. These Terms were last updated in
                January 2022.
              </p>
            </li>
            <li>
              <p class="p">
                These Terms are a legal agreement between you (“user” or “you”)
                and Talkie Limited, Phoenix Square, 4 Midland Street, Leicester,
                LE1 1TG, United Kingdom (“Talkie”, “Talkie App”, “us” or “we”)
                for access to:
              </p>
              <p class="p">
                (a) The Talkie web application software and associated tools
                (“App”);
              </p>
              <p class="p">
                (b) Online electronic recording services and Media (“Media” or
                “Services”).
              </p>
            </li>
            <li>
              <p class="p">
                Talkie Limited grants you a licence to use the Talkie App and
                Media on the basis of these Terms. We do not sell the App or
                Media to you. We always continue to own the App, Services and
                Media.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">User Consent and Acceptance</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                By downloading the App or clicking on the "accept" button you
                agree to these Terms which will bind you. The Terms include the
                Data Protection Notice defined in Clause 4.5 and limitations on
                liability in Clause 9.
              </p>
            </li>
            <li>
              <p class="p">
                If you do not agree to these Terms, we will not license the App
                and Media to you and you must stop the downloading process now.
                In this case the downloading process will terminate.
              </p>
            </li>
            <li>
              <p class="p">
                By clicking on the “accept” button you confirm that you are 13
                years of age or older. By registering and creating a profile in
                the Talkie App, you confirm that you are 13 years of age or
                older, and that you understand and agree to these Terms.
              </p>
            </li>
            <li>
              <p class="p">
                If you are a teacher, tutor or educator and you register with
                the Talkie App and use it for your personal or non-commercial
                teaching and instruction, you agree to seek, gain, record and
                manage the consents from your pupils or students, including to
                seek, gain, record and manage consents from parents, legal
                guardians and those with parental responsibility for children
                under 13 and comply with all data protection laws and relevant
                laws.
              </p>
            </li>
            <li>
              <p class="p">
                You should print or save a copy of these Terms for future
                reference.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Agreed Terms</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                These Terms apply to the App and any of the Services accessible
                through the App, including any updates or supplements to the
                App.
              </p>
            </li>
            <li>
              <p class="p">
                We may change these Terms at any time. You will be informed
                about these changes when you start the App, while using the App
                or on the App’s Website. The new terms may be displayed
                on-screen and you may be required to read and accept them to
                continue your use of the App.
              </p>
            </li>
            <li>
              <p class="p">
                From time to time updates to the App may be issued through your
                App Store. Depending on the update, you may not be able to use
                the Services until you have downloaded or streamed the latest
                version of the App and accepted any new terms.
              </p>
            </li>
            <li>
              <p class="p">
                We assume that you have obtained permission from the owners of
                the mobile telephone or handheld devices that are controlled,
                but not owned, by you and to download or stream a copy of the
                web app onto your Devices. You accept responsibility in
                accordance with these Terms for the use of the App or any
                Services on or in relation to any Device, whether or not it is
                owned by you.
              </p>
            </li>
            <li>
              <p class="p">
                The terms of our Data Protection Notice are incorporated into
                these Terms by reference and apply to all of the App’s Services.
                By using the App and its Services, you acknowledge and agree
                that internet transmissions are never completely private or
                secure. You understand that any message or information you send
                using the App or any Services you use may be read or intercepted
                by others, even if a specific transmission is encrypted.
              </p>
            </li>
            <li>
              <p class="p">
                By using the App or any of the Services, you consent to us
                collecting and using technical information about the Devices and
                related software, hardware and peripherals for Services that are
                internet-based or wireless to improve our products and to
                provide any Services to you.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Grant and scope of licence</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                In consideration of you agreeing to abide by these Terms, we
                grant you a non-transferable, non-exclusive licence to use the
                App on the Devices, subject to these terms, the Data Protection
                Notice into these Terms by reference. We reserve all other
                rights.
              </p>
            </li>
            <li>
              <p class="p">You may:</p>
              <p class="p">
                (a) download the App onto an Apple or Android Device and view,
                use and display the App on the Devices for your personal and
                non-commercial educational purposes only; and
              </p>
              <p class="p">
                (b) use the Media for your personal and non-commercial
                educational purposes only.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Licence restrictions</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                In consideration of you agreeing to abide by these Terms, we
                grant you a non-transferable, non-exclusive licence to use the
                App on the Devices, subject to these terms, the Data Protection
                Notice into these Terms by reference. We reserve all other
                rights.
              </p>
            </li>
            <li>
              <p class="p">
                Except as expressly set out in these Terms or as permitted by
                law, you agree:
              </p>
              <p class="p">
                (a) not to copy the App or Media except where such copying is
                incidental to normal use of the App, or where it is necessary
                for the purpose of back-up or operational security;
              </p>
              <p class="p">
                (b) not to rent, lease, sub-license, loan, translate, merge,
                adapt, vary or modify the App or Media;
              </p>
              <p class="p">
                (c) not to make alterations to, or modifications of, the whole
                or any part of the App, or permit the App or any part of it to
                be combined with, or become incorporated in, any other
                programmes;
              </p>
              <p class="p">
                (d) not to disassemble, decompile, reverse-engineer or create
                derivative works based on the whole or any part of the App or
                attempt to do these things.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Acceptable use restrictions</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">You must:</p>
              <p class="p">
                (a) not use the App or any Services in any unlawful manner, for
                any unlawful purpose, or in any manner inconsistent with these
                Terms, or act fraudulently or maliciously, for example, by
                hacking into or inserting malicious code, including viruses, or
                harmful data, into the App, any Services or any operating
                system;
              </p>
              <p class="p">
                (b) not infringe our intellectual property rights or those of
                any third party in relation to your use of the App or any
                Services, including the submission of any material (to the
                extent that such use is not licensed by these Terms);
              </p>
              <p class="p">
                (c) not transmit any material that is defamatory, offensive or
                otherwise objectionable in relation to your use of the App or
                any Services;
              </p>
              <p class="p">
                (d) not use the App or any Services in a way that could damage,
                disable, overburden, impair or compromise our systems or
                security or interfere with other users; and
              </p>
              <p class="p">
                (e) not collect or harvest any information or data from any
                Services or our systems or attempt to decipher any transmissions
                to or from the servers running any Services.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Intellectual property rights</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                You acknowledge that all intellectual property rights in the
                App, the Media and the Technology anywhere in the world belong
                to us or our licensors, that rights in the App are licensed (not
                sold) to you, and that you have no rights in, or to, the App,
                the Media or the Technology other than the right to use each of
                them in accordance with these Terms.
              </p>
            </li>
            <li>
              <p class="p">
                You acknowledge that you have no right to have access to the App
                in source-code form.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Limitation of liability</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                You acknowledge that the App has not been developed to meet your
                individual requirements, and that it is therefore your
                responsibility to ensure that the facilities and functions of
                the App and Media meet your general learning and educational
                requirements.
              </p>
            </li>
            <li>
              <p class="p">
                Although we make reasonable efforts to update the information in
                the App, we make no representations, warranties or guarantees,
                whether express or implied that the content of the App is
                accurate, complete or up to date.
              </p>
            </li>
            <li>
              <p class="p">
                You acknowledge that the information may be updated, altered or
                removed, from time to time, at any time, without notice to you.
              </p>
            </li>
            <li>
              <p class="p">
                We are not responsible for any data usage, roaming or other
                charges you incur when accessing the internet through your
                mobile or other Device.
              </p>
            </li>
            <li>
              <p class="p">
                We only supply the App and Media for domestic, private and
                non-commercial educational use. You agree not to use the App and
                Media for any commercial, business or resale purposes, and we
                have no liability to you for any loss of profit, loss of
                business, business interruption, or loss of business
                opportunity.
              </p>
            </li>
            <li>
              <p class="p">
                We are only responsible for loss or damage you suffer that is a
                foreseeable result of our breach of these Terms or our
                negligence up to the limit specified in condition 9.7, but we
                are not responsible for any unforeseeable loss or damage. Loss
                or damage is foreseeable if it is an obvious consequence of our
                breach or if they were contemplated by you and us at the time we
                granted you the licence to use the App and Services.
              </p>
            </li>
            <li>
              <p class="p">
                Our maximum aggregate liability under or in connection with
                these Terms (including your use of any Services) whether in
                contract, tort (including negligence) or otherwise, shall in all
                circumstances be limited to allowing you to download another
                copy of the App. This does not apply to the types of loss set
                out in Clause 9.8.
              </p>
            </li>
            <li>
              <p class="p">
                Nothing in these Terms shall limit or exclude our liability for:
              </p>
              <p class="p">
                (a) death or personal injury resulting from our negligence;
              </p>
              <p class="p">(b) fraud or fraudulent misrepresentation; and</p>
              <p class="p">
                (c) any other liability that cannot be excluded or limited by
                English law.
              </p>
            </li>
            <li>
              <p class="p">
                We do not warrant that any information in the App or Services
                are complete or accurate and we exclude all liability in respect
                of the accuracy, completeness, fitness for purpose or legality
                of that information.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Termination</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                We may terminate these Terms immediately if you commit a
                material or persistent breach of these Terms.
              </p>
            </li>
            <li>
              <p class="p">On termination for any reason:</p>
              <p class="p">
                (a) all rights granted to you under these Terms shall cease;
              </p>
              <p class="p">
                (b) you must immediately cease all activities authorised by
                these Terms, including your use of any Media or Services; and
              </p>
              <p class="p">
                (c) you must immediately delete or remove the App from all
                Devices, and immediately destroy all copies of the App and Media
                then in your possession, custody or control and certify to us
                that you have done so.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Communications</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                If you wish to contact us in writing please write to Talkie
                Limited, Phoenix Square, 4 Midland Street, Leicester, LE1 1TG,
                United Kingdom or email us at
                <a href="mailto:hello@talkietheapp.com"
                  >hello@talkietheapp.com</a
                >.
              </p>
            </li>
          </ol>
        </li>
      </section>

      <section class="terms-of-service-content-section">
        <li>
          <h4 class="h4">Other terms</h4>
          <ol class="terms-of-service-content-list-numbered-decimal">
            <li>
              <p class="p">
                Each of the conditions in these Terms operates separately. If
                any court or competent authority decides that any of them are
                unlawful or unenforceable, the remaining conditions will remain
                in full force and effect.
              </p>
            </li>
            <li>
              <p class="p">
                Please note that these Terms, its subject matter and its
                formation, are governed by English law. You and Talkie Limited
                both agree that the courts of England and Wales will have
                exclusive jurisdiction.
              </p>
            </li>
            <li>
              <p class="p">
                This agreement has been entered into on the date of your first
                download, registration and or agreement in the App.
              </p>
            </li>
          </ol>
        </li>
      </section>
    </ol>
  </article>
</template>

<script>
export default {
  name: "ServicesTermsOfService",
};
</script>

<style scoped>
.terms-of-service-wrapper,
.terms-of-service-content-wrapper,
.terms-of-service-content-section {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}
.terms-of-service-wrapper {
  background-color: var(--t-white);
}
.terms-of-service-content-section {
  line-height: 1.5;
}
li {
  line-height: 1.5;
}
.terms-of-service-content-center {
  margin: auto;
}
.terms-of-service-content-list-numbered-decimal {
  padding: var(--t-space-12) 0;
  list-style-type: decimal;
}
ol {
  counter-reset: item;
}
li {
  display: block;
  position: relative;
}
li:before {
  position: absolute;
  content: counters(item, ".") " ";
  counter-increment: item;
  --li-font-size: var(--t-fs-body);
}

/* Responsive variants */
@media (max-width: 599px) {
  .terms-of-service-wrapper {
    max-width: 100%;
    gap: var(--t-space-12);
    padding: var(--t-space-32) var(--t-space-40);
    margin-top: var(--t-space-50);
    margin-bottom: var(--t-space-50);
    border-radius: var(--t-br-medium);
  }
  .terms-of-service-content-wrapper {
    gap: var(--t-space-12);
  }
  .terms-of-service-content-section {
    gap: var(--t-space-8);
  }
  li:before {
    left: -10%;
    font-size: calc(var(--li-font-size) * 0.75);
  }
}
@media (min-width: 600px) {
  .terms-of-service-wrapper {
    max-width: 80%;
    gap: var(--t-space-36);
    padding: var(--t-space-48);
    margin-top: var(--t-space-70);
    margin-bottom: var(--t-space-70);
    border-radius: var(--t-br-medium);
  }
  .terms-of-service-content-wrapper {
    gap: var(--t-space-36);
  }
  .terms-of-service-content-section {
    gap: var(--t-space-16);
  }
  li:before {
    left: -9%;
    font-size: calc(var(--li-font-size) * 0.85);
  }
}
@media (min-width: 900px) {
  li:before {
    left: -5.5%;
  }
}
@media (min-width: 1200px) {
  .terms-of-service-wrapper {
    max-width: 90%;
    padding: var(--t-space-48);
    border-radius: var(--t-br-large);
  }
  .terms-of-service-content-section {
    gap: var(--t-space-20);
  }
  li:before {
    left: -4.5%;
    font-size: calc(var(--li-font-size) * 0.95);
  }
}
</style>
